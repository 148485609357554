import { SortOrder } from 'src/api/common/types'

export const noop = () => {}

export const isString = (value: any): value is string => typeof value === 'string'

export const isNumber = (value: any): value is number => typeof value === 'number' && !isNaN(value)

export const normalize = <T>(array: T[], keyExtractor: (item: T) => string): Record<string, T> =>
  array.reduce((normalized: Record<string, T>, item: T) => {
    const key = keyExtractor(item)
    normalized[key] = item
    return normalized
  }, {})

export const sortComparator =
  (order: SortOrder) =>
  <T extends string | number>(a: T, b: T) => {
    const value = isString(a) ? a.toLocaleLowerCase() : a
    const valueToCompare = isString(b) ? b.toLocaleLowerCase() : b

    if (value > valueToCompare) {
      return order === 'asc' ? 1 : -1
    }

    if (valueToCompare > value) {
      return order === 'asc' ? -1 : 1
    }

    return 0
  }

export const sortBy = <T, X extends string | number>(arr: T[], fn: (x: T) => X, order: SortOrder = 'asc') =>
  [...arr].sort((a, b) => sortComparator(order)(fn(a), fn(b)))

export const getObjectValuesCount = (obj: object) => Object.values(obj).filter(value => value.length).length
