import { LabelConfig } from '@platform-ui-kit/components-library'
import { TooltipConfig } from '@platform-ui-kit/components-library/dist/types/types/common'

export const useCommonLabelProps = ({
  labelConfig,
  labelTooltipConfig,
}: {
  labelConfig?: LabelConfig
  labelTooltipConfig?: TooltipConfig
}) => {
  let labelConfigResult = labelConfig
  let labelTooltipConfigResult = labelTooltipConfig

  if (labelConfigResult) {
    const { text, description, icon, locales } = labelConfigResult

    labelConfigResult = {
      text,
      description,
      locales,
      icon,
      ...(!icon && !!description && { icon: 'wpp-icon-info' }),
    }

    if (labelTooltipConfigResult) {
      const { placement, ...rest } = labelTooltipConfigResult

      labelTooltipConfigResult = {
        ...rest,
        ...(!!description && !placement && { placement: 'right' }),
      }
    } else {
      if (description) {
        labelTooltipConfigResult = { placement: 'right' }
      }
    }
  }

  return { labelConfig: labelConfigResult, labelTooltipConfig: labelTooltipConfigResult }
}
