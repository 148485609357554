// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ATXxr{display:flex;justify-content:flex-end}.cOaG4{margin-left:12px}", "",{"version":3,"sources":["webpack://./src/components/deleteSource/DeleteSource.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,wBAAA,CAGF,OACE,gBAAA","sourcesContent":[".actions {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.delete {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "ATXxr",
	"delete": "cOaG4"
};
export default ___CSS_LOADER_EXPORT___;
