// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CC9r8{margin-bottom:28px}.uwBAy .ItoQr{display:flex}.uwBAy .ItoQr::part(card){display:flex;flex-direction:column;min-height:calc(100vh - var(--wpp-os-header-height) - 116px);padding:24px 32px;box-shadow:0 0 1px rgba(52,58,63,.1),0 1px 5px rgba(52,58,63,.1)}.luLAL{display:flex;flex:1 1 auto;flex-wrap:wrap;align-content:center;justify-content:center}.kUdlX{flex:1 1;min-height:300px;margin-top:16px}.Lyww5{display:flex !important}", "",{"version":3,"sources":["webpack://./src/pages/newsSettings/NewsSettings.module.scss"],"names":[],"mappings":"AAEA,OACE,kBAAA,CAIA,cACE,YAAA,CAEA,0BACE,YAAA,CACA,qBAAA,CACA,4DAAA,CACA,iBAAA,CACA,gEAAA,CAKN,OACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,oBAAA,CACA,sBAAA,CAGF,OACE,QAAA,CACA,gBAAA,CACA,eAAA,CAGF,OAEE,uBAAA","sourcesContent":["$pageTitleHeight: 116px;\n\n.header {\n  margin-bottom: 28px;\n}\n\n.container {\n  .card {\n    display: flex;\n\n    &::part(card) {\n      display: flex;\n      flex-direction: column;\n      min-height: calc(100vh - var(--wpp-os-header-height) - $pageTitleHeight);\n      padding: 24px 32px;\n      box-shadow: 0 0 1px rgb(52 58 63 / 10%), 0 1px 5px rgb(52 58 63 / 10%);\n    }\n  }\n}\n\n.loading {\n  display: flex;\n  flex: 1 1 auto;\n  flex-wrap: wrap;\n  align-content: center;\n  justify-content: center;\n}\n\n.table {\n  flex: 1;\n  min-height: 300px;\n  margin-top: 16px;\n}\n\n.moreMenu {\n  // TODO: remove important after CL fix\n  display: flex !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "CC9r8",
	"container": "uwBAy",
	"card": "ItoQr",
	"loading": "luLAL",
	"table": "kUdlX",
	"moreMenu": "Lyww5"
};
export default ___CSS_LOADER_EXPORT___;
