import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { FormProvider } from 'react-hook-form'

import { useUpdateNewsItemApi } from 'api/news/mutations/useUpdateNewsItemApi'
import { Flex } from 'components/common/flex/Flex'
import { mapFormToNewsItem, mapNewsItemToForm, defaultValues, validationSchema } from 'components/editNewsItem/utils'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormCountriesSelect } from 'components/form/formSelect/FormCountriesSelect'
import { FormSideModal } from 'components/surface/sideModal/FormSideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'providers/toast/ToastProvider'
import { News } from 'types/news/news'

interface Props {
  data: MayBeNull<News>
  isOpen: boolean
  onClose: () => void
}

export const EditNewsItem = ({ data, onClose, isOpen }: Props) => {
  const queryClient = useQueryClient()
  const { mutateAsync: handleUpdateNewsItem } = useUpdateNewsItemApi()
  const { osContext } = useOs()
  const { tenant } = osContext
  const { enqueueToast } = useToast()

  const form = useForm({
    defaultValues: data ? mapNewsItemToForm(data) : defaultValues,
    validationSchema,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      await handleUpdateNewsItem({
        tenantId: tenant.azMeta.organizationsId,
        newsItem: mapFormToNewsItem(values),
        newsId: data?.id || '',
      })

      await queryClient.invalidateQueries([ApiQueryKeys.NEWS])

      onClose()
      enqueueToast({
        message: 'News item is updated successfully',
        type: 'success',
      })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  })

  return (
    <FormProvider {...form}>
      <FormSideModal
        data-testid="edit-news-item-side-modal"
        open={isOpen}
        formProps={{
          onSubmit,
        }}
        size="m"
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={() => {
          reset()
        }}
        disableOutsideClick
      >
        <WppTypography slot="header" type="2xl-heading">
          Edit news item
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <Flex direction="column">
            <WppTypography type="m-strong" data-testid="news-item-title">
              News title
            </WppTypography>
            {!!data?.title && (
              <WppTypography type="s-body" data-testid="news-item-name">
                {data.title}
              </WppTypography>
            )}
          </Flex>
          <Flex direction="column">
            <WppTypography type="m-strong" data-testid="news-item-source-title">
              News source
            </WppTypography>
            {!!data?.tenant_credentials_name && (
              <WppTypography type="s-body" data-testid="news-item-source-name">
                {data.tenant_credentials_name}
              </WppTypography>
            )}
          </Flex>
          <FormCountriesSelect
            type="multiple"
            name="countryAlpha2Codes"
            labelConfig={{ text: 'Country' }}
            placeholder="Select country"
            data-testid="country-select"
          />
          <FormCheckbox
            name="isPrimary"
            labelConfig={{
              text: 'Starred',
            }}
          />
          <FormCheckbox
            name="isHidden"
            labelConfig={{
              text: 'Hidden',
            }}
          />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton
            variant="secondary"
            size="m"
            type="button"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </WppButton>
          <WppButton
            variant="primary"
            size="m"
            type="submit"
            loading={isSubmitting}
            data-testid="save-button"
            disabled={!isDirty}
          >
            Save
          </WppButton>
        </Flex>
      </FormSideModal>
    </FormProvider>
  )
}
