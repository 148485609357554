export const EVENTS = {
  PAGE: {
    NEWS: 'page_news',
    SETTINGS: 'page_news_settings',
  },
  ACTIONS: {
    SETTINGS: 'action_news_settings',
    SYNC_NEWS: 'action_news_settings_sync_news',
    ADD_NEWS_SOURCE: 'action_news_settings_add_source',
    SETTINGS_BACK: 'action_news_settings_back',
  },
}
