import { facadeApi } from 'api'
import { CredentialsAddUpdateDTO } from 'types/credentianls/credentials'

export interface Params {
  tenantId: string
  credential: CredentialsAddUpdateDTO
}

export const createCredentialApi = ({ tenantId, credential }: Params) =>
  facadeApi.post<CredentialsAddUpdateDTO>(
    '/credentials',
    { ...credential },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
