import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import {
  FormSelect,
  FormSelectOption,
  FormSelectSkeleton,
  FormSelectProps,
} from 'components/form/formSelect/FormSelect'
import { useCountries } from 'hooks/useCountries'

type Props = Omit<FormSelectProps<FormSelectOption>, 'options'>

export const FormCountriesSelect = ({
  required,
  type = 'single',
  placeholder,
  name,
  labelConfig,
  'data-testid': dataTestid,
}: Props) => {
  const { countriesOptions, isLoading } = useCountries()

  return (
    <>
      {isLoading ? (
        <FormSelectSkeleton />
      ) : (
        <FormSelect
          required={required}
          withSearch
          withFolder
          type={type}
          placeholder={placeholder}
          name={name}
          options={countriesOptions}
          labelConfig={labelConfig}
          renderOptionContent={({ label, logoThumbnail }) => (
            <Flex slot="label" gap={8}>
              <Avatar size="xs" src={logoThumbnail?.url || ''} name={label} /> <span>{label}</span>
            </Flex>
          )}
          data-testid={dataTestid}
        />
      )}
    </>
  )
}
