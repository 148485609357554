// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wr6Ke{margin-bottom:28px}.i0cwZ{height:calc(100vh - 180px)}.aLzaB{width:320px}.g6hPJ{display:inline-block;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/pages/newsList/NewsList.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CAGF,OACE,0BAAA,CAGF,OACE,WAAA,CAGF,OACE,oBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".header {\n  margin-bottom: 28px;\n}\n\n.emptyState {\n  height: calc(100vh - 180px);\n}\n\n.selectContainer {\n  width: 320px;\n}\n\n.countrySelectOptionLabel {\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "wr6Ke",
	"emptyState": "i0cwZ",
	"selectContainer": "aLzaB",
	"countrySelectOptionLabel": "g6hPJ"
};
export default ___CSS_LOADER_EXPORT___;
