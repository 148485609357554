import { facadeApi } from 'api'
import { Credentials } from 'types/credentianls/credentials'

export interface Params {
  tenantId: string
}

export const fetchCredentialsApi = ({ tenantId }: Params) =>
  facadeApi.get<Credentials[]>('/credentials', {
    params: {
      tenant_id: tenantId,
    },
  })
