import { facadeApi } from 'api'
import { NewsById } from 'types/news/newsById'

export interface Params {
  newsId?: string
  tenantId: string
}

export const fetchNewsByIdApi = ({ newsId, tenantId }: Params) =>
  facadeApi.get<NewsById>(`/news/${newsId}`, {
    params: {
      tenant_id: tenantId,
    },
  })
