// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uir16{position:relative;display:flex;flex-direction:column}.uir16::part(card){display:flex;flex-direction:column;height:340px;padding:0}.uir16::part(actions-wrapper){top:4px;right:4px}.rwfKD::part(button){background-color:#fff}.rwfKD::part(button):hover{background:var(--wpp-grey-color-300)}.dKXm3{height:148px;object-fit:cover;object-position:center}.EaXy8{display:flex;flex:1 1;flex-direction:column;justify-content:flex-start;padding:24px 24px 16px}.w5CsK{margin-bottom:13px}.miwob{margin-top:auto}.cAjNQ{--wpp-typography-color: var(--wpp-grey-color-600)}", "",{"version":3,"sources":["webpack://./src/components/newsCard/NewsCard.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,YAAA,CACA,qBAAA,CAEA,mBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,SAAA,CAGF,8BACE,OAAA,CACA,SAAA,CAKF,qBACE,qBAAA,CAEA,2BACE,oCAAA,CAKN,OACE,YAAA,CACA,gBAAA,CACA,sBAAA,CAGF,OACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,0BAAA,CACA,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,eAAA,CAGF,OACE,iDAAA","sourcesContent":[".card {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n\n  &::part(card) {\n    display: flex;\n    flex-direction: column;\n    height: 340px;\n    padding: 0;\n  }\n\n  &::part(actions-wrapper) {\n    top: 4px;\n    right: 4px;\n  }\n}\n\n.whiteActionButton {\n  &::part(button) {\n    background-color: white;\n\n    &:hover {\n      background: var(--wpp-grey-color-300);\n    }\n  }\n}\n\n.cover {\n  height: 148px;\n  object-fit: cover;\n  object-position: center;\n}\n\n.content {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 24px 24px 16px;\n}\n\n.author {\n  margin-bottom: 13px;\n}\n\n.footer {\n  margin-top: auto;\n}\n\n.date {\n  --wpp-typography-color: var(--wpp-grey-color-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "uir16",
	"whiteActionButton": "rwfKD",
	"cover": "dKXm3",
	"content": "EaXy8",
	"author": "w5CsK",
	"footer": "miwob",
	"date": "cAjNQ"
};
export default ___CSS_LOADER_EXPORT___;
