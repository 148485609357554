import { MayBeNull } from '@wpp-open/core'

import { facadeApi } from 'api'
import { News } from 'types/news/news'

export interface Params {
  tenantId: string
  hidden: boolean
  primary: MayBeNull<boolean>
  search: string
  countryAlpha2Codes: string[]
  tenantCredentialsIds: string[]
}

export const fetchNewsApi = ({ tenantId, hidden, countryAlpha2Codes, tenantCredentialsIds, primary, search }: Params) =>
  facadeApi.post<News[]>(
    '/news/search',
    {
      hidden,
      ...(!!search && { search }),
      ...(!!countryAlpha2Codes?.length && { countryAlpha2Codes }),
      ...(!!tenantCredentialsIds?.length && { tenantCredentialsIds }),
      ...(!!countryAlpha2Codes?.length && { includeNoCountries: false }), //Need to be fixed, Max is aware
      ...(primary !== null && { primary }),
    },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
