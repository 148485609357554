import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import styles from 'app/App.module.scss'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { NewsList } from 'pages/newsList/NewsList'
import { NewsSettings } from 'pages/newsSettings/NewsSettings'
import { NewsViewer } from 'pages/newsViewer/NewsViewer'
import { ApiProvider } from 'providers/api/ApiProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'
import { EVENTS } from 'utils/events'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export function App() {
  const {
    osContext: { baseUrl },
    osApi,
  } = useOs()
  const { hasPermission } = useHasPermission()

  useEffectOnce(() => {
    osApi.analytics.track({
      type: AnalyticsActionType.page,
      payload: EVENTS.PAGE.NEWS,
    })
  })

  if (!baseUrl) {
    return null
  }

  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)

  return (
    <BrowserRouter basename={baseUrl}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <ApiProvider>
            <div
              className={styles.container}
              data-build={process.env.REACT_APP_BUILD_NUMBER}
              data-build-branch={process.env.REACT_APP_BRANCH}
              data-commit={process.env.REACT_APP_COMMIT_SHA}
            >
              <div className={styles.innerContent}>
                <Routes>
                  <Route path="/" element={<NewsList />} />
                  <Route path=":newsId" element={<NewsViewer />} />
                  <Route path="/settings" element={hasAccessToNewsSettings ? <NewsSettings /> : <Navigate to="/" />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          </ApiProvider>
        </ToastProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
