import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'

import { useDeleteCredentialApi } from 'api/news/mutations/useDeleteCredentialApi'
import styles from 'components/deleteSource/DeleteSource.module.scss'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'providers/toast/ToastProvider'
import { Credentials } from 'types/credentianls/credentials'

interface Props {
  open: boolean
  source: Credentials
  onSubmit?: () => void
  onClose: () => void
}

export const DeleteSource = ({ open, source, onSubmit, onClose }: Props) => {
  const queryClient = useQueryClient()
  const { mutateAsync: handleDeleteCredential, isLoading } = useDeleteCredentialApi()
  const { osContext } = useOs()
  const { tenant } = osContext
  const { enqueueToast } = useToast()

  const handleDelete = async () => {
    try {
      await handleDeleteCredential({
        tenantId: tenant.azMeta.organizationsId,
        credentialId: source?.id || '',
      })

      await queryClient.invalidateQueries([ApiQueryKeys.CREDENTIALS])

      onSubmit?.()
      onClose()
      enqueueToast({
        message: 'News source is removed successfully',
        type: 'success',
      })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  return (
    <Modal open={open} onWppModalClose={onClose}>
      <WppTypography slot="header" type="xl-heading" data-testid="remove-sharepoint-source-modal-header">
        Remove Sharepoint news channel
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        “{source?.name}” source will be removed and this can not be undone. Are you sure you want to remove it?
      </WppTypography>

      <div slot="actions" className={styles.actions}>
        <WppButton onClick={onClose} variant="secondary">
          Cancel
        </WppButton>
        <WppButton className={styles.delete} onClick={handleDelete} size="s" variant="destructive" loading={isLoading}>
          Remove
        </WppButton>
      </div>
    </Modal>
  )
}
