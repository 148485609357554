import { facadeApi } from 'api'
import { CredentialsAddUpdateDTO } from 'types/credentianls/credentials'

export interface Params {
  tenantId: string
  credential: CredentialsAddUpdateDTO
  credentialId: string
}

export const updateCredentialApi = ({ tenantId, credential, credentialId }: Params) =>
  facadeApi.put<CredentialsAddUpdateDTO>(
    `/credentials/${credentialId}`,
    { ...credential },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
