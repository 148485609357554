import { WppModal } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import { Portal } from 'components/common/portal/Portal'
import { useKeepUnmountedOnClose } from 'components/surface/utils'

type Props = ComponentPropsWithoutRef<typeof WppModal>

export const Modal = forwardRef<HTMLWppModalElement, Props>((props, ref) => {
  const [shouldRender, modifiedProps] = useKeepUnmountedOnClose(props, 'onWppModalCloseComplete')

  return (
    <>
      {shouldRender && (
        <Portal>
          <WppModal ref={ref} {...modifiedProps} />
        </Portal>
      )}
    </>
  )
})
