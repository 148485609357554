import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useCredentialsApi } from 'api/news/queries/useCredentialsApi'
import { sortBy } from 'utils/common'

export const useCredentials = () => {
  const { osContext } = useOs()
  const { tenant } = osContext
  const { isLoading, data } = useCredentialsApi({
    params: {
      tenantId: tenant.azMeta.organizationsId,
    },
  })

  const credentialOptions = useMemo(
    () =>
      sortBy(
        data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        ({ label }) => label,
      ),
    [data],
  )

  return { data, credentialOptions, isLoading }
}
