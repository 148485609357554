import styles from 'components/imagePlaceholder/imagePlaceholder.module.scss'
import { Image } from 'components/svg/image/Image'

export const ImagePlaceholder = () => {
  return (
    <div className={styles.container}>
      <Image />
    </div>
  )
}
