import { format } from 'date-fns'

import { DateFormat } from 'types/common/dates'

type DateInput = string | number | Date

export const formatDate = (date: DateInput, locale?: string) => {
  const stringLocale = locale ? DateFormat[locale as keyof typeof DateFormat] : DateFormat['en-gb']
  return format(new Date(date), stringLocale)
}
