import { facadeApi } from 'api'
import { NewsItemUpdateDTO } from 'types/news/news'

export interface Params {
  tenantId: string
  newsItem: NewsItemUpdateDTO
  newsId: string
}

export const updateNewsItemApi = ({ tenantId, newsItem, newsId }: Params) =>
  facadeApi.patch<NewsItemUpdateDTO>(
    `/news/${newsId}`,
    { ...newsItem },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
