import styles from 'components/svg/common.module.scss'

export const Image = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.9444 2C27.7366 2 30 4.26345 30 7.05556V24.9444C30 27.7366 27.7366 30 24.9444 30H7.05556C4.26345 30 2 27.7366 2 24.9444V7.05556C2 4.26345 4.26345 2 7.05556 2H24.9444ZM25.8475 27.5133L16.8161 18.6675C16.4038 18.2637 15.7669 18.227 15.3137 18.5573L15.1837 18.6674L6.15092 27.5127C6.43396 27.6124 6.73842 27.6667 7.05556 27.6667H24.9444C25.261 27.6667 25.5649 27.6126 25.8475 27.5133L16.8161 18.6675L25.8475 27.5133ZM24.9444 4.33333H7.05556C5.55211 4.33333 4.33333 5.55211 4.33333 7.05556V24.9444C4.33333 25.2686 4.39 25.5796 4.49397 25.8679L13.5512 17.0002C14.8471 15.7313 16.8827 15.671 18.249 16.8191L18.4487 17.0004L27.5055 25.8695C27.6098 25.5807 27.6667 25.2692 27.6667 24.9444V7.05556C27.6667 5.55211 26.4479 4.33333 24.9444 4.33333ZM21.0588 7.44444C22.9937 7.44444 24.5621 9.01292 24.5621 10.9477C24.5621 12.8825 22.9937 14.451 21.0588 14.451C19.124 14.451 17.5556 12.8825 17.5556 10.9477C17.5556 9.01292 19.124 7.44444 21.0588 7.44444ZM21.0588 9.77778C20.4127 9.77778 19.8889 10.3016 19.8889 10.9477C19.8889 11.5939 20.4127 12.1177 21.0588 12.1177C21.705 12.1177 22.2288 11.5939 22.2288 10.9477C22.2288 10.3016 21.705 9.77778 21.0588 9.77778Z"
      className={styles.fillPrimary400}
    />
  </svg>
)
